import { route } from 'preact-router';
import { Address } from '.';
import { User } from './api';
import { PackQuantity } from './details';
import * as api from './api';

import * as commerce from './commerce';
import { OrderFormItem } from './cart';
import { Item } from './commerce';

export interface Product {
    category: string;
    image: string;
    brand: string;
    description: any;
    sku: string;
    name: string;
    price: number;

}

export interface CartItemChemical {
    code: string;
    name: string;
    unspsc: string | null;
}
export interface CartItem {
    packs: PackQuantity[];
    id: string;
    chemical: CartItemChemical;
    options: any,
};

export class Cart {
    static version = 2;
    version = Cart.version;
    items: CartItem[] = [];
}

export class Account {

}

export interface Chemical {
    id: string;
    molecule: string;
    name: string;
    cas: string;
    mdl: string;
    slog_p: number;
    tpsa: number;
    exact_mw: number;
    lipinski_hba: number;
    lipinski_hbd: number;
    rotatable_bonds: number;
    hbd: number;
    hba: number;
    amide_bonds: number;
    hetero_atoms: number;
    heavy_atoms: number;
    atoms_count: number;
    rings: number;
    aromatic_rings: number;
    saturated_rings: number;
    aliphatic_rings: number;
    aromatic_heterocycles: number;
    saturated_heterocycles: number;
    aliphatic_heterocycles: number;
    aromatic_carbocycles: number;
    saturated_carbocycles: number;
    aliphatic_carbocycles: number;
    fraction_csp3: number;
    maccs_fingerprints: string;
    rdkit_molecule_inchi_code: string;
    rdkit_molecule_inchi_key: string;
    rdkit_molecule_canonical: string;
    largest_chain: number;
    largest_pi_chain: number;
    rotatable_bonds_count: number;
    lipinski_s_rule_of_five: number;
    topological_polar_surface_area: number;
    formal_charge: number;
    formal_charge_pos: number;
    formal_charge_neg: number;
    sp3_character: number;
    heteroatoms_in_aromatic_rings: number;
    total_atoms: number;
    carbons: number;
    hydrogens: number;
    cis_trans_bonds: number;
    connected_components: number;
    chiral_centers: number;
    aromatic_bonds: number;
    formula: string[];
    aliphatic_bonds: number;
    aliphatic_atoms: number;
    aromatic_atoms: number;
    concepts: string[];
    svg_path: string;
    atoms: string[];
    count_list_of_atoms: number;
    unique_atoms_count: number;
    reference: number;
}

export interface SalesOrderOverview {
    id: string;
    tranid: string;
    otherrefnum: string;
    createddate: string;
    foreigntotal: string;
    shipdate: string;
    linkedtrackingnumberlist: string;
    status: string;
}


export interface SearchConfig {
    q: string;
    ranges: { [key: string]: [number, number] };
    concepts: string[];
    exclude_concepts: string[];
    molecule: {
        block: string,
        relation: string,
        distance: number,
    },
    offset: number;
    limit: number;
}

export function getCurrency(state: Model): string {
    if (state.user) {
        return state.user.currency;
    } else if (state.location) {
        return state.location == 'EU' ? 'EUR' : 'GBP';
    } else {
        return 'GBP';
    }
}

export class Model {


    sketcher?: any;
    user?: User;
    token?: string;

    cartClass = '';
    quoteClass = '';
    search: SearchConfig = {
        q: '',
        ranges: {} as { [key: string]: [number, number] },
        concepts: [] as string[],
        exclude_concepts: [] as string[],
        molecule: {
            block: '',
            relation: '',
            distance: 1,
        },
        offset: 0,
        limit: 12,
    };
    filter: { [key: string]: string[] } = {};

    searchResponse: SearchResult = {
        total: 0,
        data: [],
        limit: 12,
        offset: 0,
        took: 0,
        query: '',
        loading: false,
    };

    packs: Pack[] = [];

    products: Product[] = [];


    cart = new Cart();
    quote = new Cart();
    debouncedSearch: any; // timeout handle
    orders: {
        items: (SalesOrderOverview | commerce.Order)[],
    } = { items: [] };
    quotes: {
        items: SalesOrderOverview[],
    } = { items: [] };
    addresses: AddressOverview[] = [];
    stockReady = false;
    loadingHeight?: number;

    location?: string;

    quickQuoteOpen = false;

    constructor() {

        if (localStorage.getItem('location')) {
            this.location = localStorage.getItem('location') as any;
        }

        if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user') != 'undefined') {
            console.log(localStorage.getItem('user'));
            this.token = localStorage.getItem('token') || undefined;
            this.user = JSON.parse(localStorage.getItem('user') as any);
        }

        for (const kind of ['cart', 'quote'] as ('cart' | 'quote')[]) {
            const cartSuffix = this.user && !this.user.punchout_id ? this.user.id : '';
            if (localStorage.getItem('cart' + cartSuffix)) {
                const cart = JSON.parse(localStorage.getItem(kind + cartSuffix) as any);
                if (cart?.version == Cart.version) {
                    this[kind] = cart;
                }
            }
        }



        const params: any = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as any),
        });
        this.search.q = params.q || '';
    }




}

const LoadingSearch = (search: any, response: SearchResult): SearchResult => ({
    total: response.total,
    data: [],
    limit: search.limit,
    offset: search.offset,
    took: 0,
    query: '',
    loading: true,
});


export type Action
    = {
        data: any; type: 'logged', token: string, user: User
    }
    | { type: 'register' }
    | { type: 'logout' }
    | { type: 'add', chemical: CartItemChemical, options: any, packs: PackQuantity[] }
    | { type: 'quote.add', chemical: CartItemChemical, quantity: number, options: any, packs: PackQuantity[] }
    | { type: 'remove', kind: 'cart' | 'quote', id: number }
    | { type: 'quantity', kind: 'cart' | 'quote'; id: number; size: string; quantity: number }
    | { type: 'unit', kind: 'cart' | 'quote'; id: number; unit: string }
    | { type: 'products', products: Product[] }
    | { type: 'filter', filter: any }
    | { type: 'filter.in', key: string; option: string; value: boolean }
    | { type: 'search', search?: any }
    | { type: 'filter.clear' }
    | { type: 'endanimate' }
    | { type: 'search.response', response: SearchResult }
    | { type: 'search.packs', packs: Pack[] }
    | { type: 'search.packs.append', packs: Pack[] }
    | { type: 'setRange', field: string, value: [number, number] }
    | { type: 'setPageSize', size: number }
    | { type: 'setPage', page: number }
    | { type: 'setMolecule', molecule: { block: string; relation: string } }
    | { type: 'setMoleculeBlock', block: string }
    | { type: 'setMoleculeRelation', relation: string }
    | { type: 'setMoleculeDistance', distance: number }
    | { type: 'setSearchQuery', query: string }
    | { type: 'debounceSearch', search?: any }
    | { type: 'setConceptGroups', value: string[] }
    | { type: 'setExcludeConceptGroups', value: string[] }
    | { type: 'resetFilters' }
    | { type: 'SET_ORDERS', orders: QueryData<SalesOrderOverview> }
    | { type: 'SET_QUOTES', quotes: QueryData<SalesOrderOverview> }
    | { type: 'SET_ADDRESSES', addresses: AddressOverview[] }
    | { type: 'SKETCHER_INSTANCE', sketcher: any }
    | { type: 'cart.clear' }
    | { type: 'quote.clear' }
    | { type: 'user.billing', id: number }
    | { type: 'user.shipping', id: number }
    | { type: 'location.set', location: string }
    | { type: 'quoteQuote.open', value: boolean }
    | { type: 'stockReady', value: boolean }
    | { type: 'draft.to.cart', value: { items: Item[] } }


export interface QueryData<T> {
    items: T[];
}

export interface AddressOverview extends Address {
}


let lastSearch = '';

function getCartSuffix(state: Model): string {
    return state.user && !state.user.punchout_id ? state.user.id : '';
}

function addToCartOrQuote(state: Model, action: { chemical: CartItemChemical, options: any, packs: PackQuantity[] }, kind: 'cart' | 'quote') {
    console.log('addToCartOrQuote', action, kind);
    const id = hash([action.chemical.code, action.options]);
    const item = state[kind].items.find(it => it.id == id);
    if (item) {
        for (const newPackQuantity of action.packs) {
            const packQuantity = item.packs.find(p => p.pack.id == newPackQuantity.pack.id);
            if (packQuantity) {
                packQuantity.quantity += newPackQuantity.quantity;
            } else {
                item.packs.push(newPackQuantity);
            }
        }
    } else {
        state[kind].items.push({ ...action, id });
    }
    state[`${kind}Class`] = 'animate__animated animate__headShake';

    const cartSuffix = state.user && !state.user.punchout_id ? state.user.id : '';
    localStorage.setItem(kind + cartSuffix, JSON.stringify(state[kind]));

}

export function RootReducer(state: Model, action: Action): Model {
    (window as any).state = state;
    const isSketcher = window.location.pathname.startsWith('/sketcher') || window.location.pathname.startsWith('/advanced');
    const q = isSketcher ? '' : state.search.q;

    console.log(action);

    const cartSuffix = state.user && !state.user.punchout_id ? state.user.id : '';
    const dispatch = () => (window as any).dispatch;
    switch (action.type) {
        case 'draft.to.cart':

            const cartItems: CartItem[] = [];
            for (const line of action.value.items) {
                let cartItem = cartItems.find(it => it.chemical.name == line.name);
                if (!cartItem) {
                    cartItem = { id: Date.now().toString(), options: {}, chemical: { code: line.sku, name: line.name, unspsc: '' }, packs: [] };
                    cartItems.push(cartItem);
                }
                cartItem.packs.push({
                    pack: {
                        id: parseInt(line.id.toString()),
                        sku: line.sku,
                        code: line.sku,
                        size: '',
                        price: line.price * 0.001,
                        availability: '',
                        availability_reason: ''
                    },
                    quantity: line.quantity
                });
            }


            const newStateFromDraft = { ...state, cart: { ...state.cart, items: cartItems } };

            const cartSuffix = state.user && !state.user.punchout_id ? state.user.id : '';
            localStorage.setItem('cart' + cartSuffix, JSON.stringify(newStateFromDraft.cart));

            return newStateFromDraft;

        case 'stockReady':
            return { ...state, stockReady: action.value };
        case 'quoteQuote.open':
            return { ...state, quickQuoteOpen: action.value };
        case 'location.set':
            localStorage.setItem('location', action.location);
            return { ...state, location: action.location };
        case 'user.billing':
            const user = state.user ? { ...state.user, default_billing: action.id } : undefined;
            if (user) {
                localStorage.setItem('user', JSON.stringify(user));
                api.updateUser(user).then(res => alert('Default billing address updated'));;
            }
            return { ...state, user };
        case 'user.shipping':
            const user1 = state.user ? { ...state.user, default_shipping: action.id } : undefined;
            if (user1) {
                localStorage.setItem('user', JSON.stringify(user1));
                api.updateUser(user1).then(res => alert('Default shipping address updated'));;
            }
            return { ...state, user: user1 };
        case 'SKETCHER_INSTANCE':
            return { ...state, sketcher: action.sketcher };
        case 'SET_ORDERS':
            return { ...state, orders: action.orders };
        case 'SET_QUOTES':
            return { ...state, quotes: action.quotes };
        case 'SET_ADDRESSES':
            return { ...state, addresses: action.addresses };
        case 'search':
            chemsearch(action.search || state.search)
                .then(res => {
                    dispatch()({ type: 'search.response', response: res });
                    if (state.user || state.location) {
                        getInventory(res.data.map(it => it.molecule.code), state.location).then(res => {
                            dispatch()({ type: 'search.packs', packs: res });
                            //                    dispatch({ type: 'inventory.response', response: res });

                        });
                    }
                });

            dispatch()({ type: 'search.response', response: LoadingSearch(action.search || state.search, state.searchResponse) });
            return { ...state, stockReady: false, searchResponse: LoadingSearch(action.search || state.search, state.searchResponse) };
        case 'debounceSearch':
            let textSearch = JSON.stringify(action.search || state.search);
            /*if (textSearch == lastSearch && state.searchResponse.total) {
                console.log('search not debounced');
                return state;
            }*/
            lastSearch = textSearch;
            const copy = JSON.parse(textSearch);
            if ((window as any).debouncedSearch) {
                clearTimeout((window as any).debouncedSearch);
            }
            (window as any).debouncedSearch = setTimeout(() => {
                console.log('search debounced');
                dispatch()({ type: 'search.response', response: LoadingSearch(copy, state.searchResponse) });
                chemsearch(copy)
                    .then(res => {
                        dispatch()({ type: 'search.response', response: res });
                        if (state.user || state.location) {

                            getInventory(res.data.map(it => it.molecule.code), state.location).then(res => {
                                console.log(res);
                                dispatch()({ type: 'search.packs', packs: res });
                                //                    dispatch({ type: 'inventory.response', response: res });

                            });
                        }
                    });
            }, 300);
            //  state.debouncedSearch = debouncedSearch;
            return { ...state, searchResponse: LoadingSearch(copy, state.searchResponse) };
        case 'resetFilters':
            const newState6 = { ...state, search: { ...state.search, concepts: [], ranges: {}, offset: 0 }, searchResponse: LoadingSearch(state.search, state.searchResponse) };
            dispatch()({ type: 'debounceSearch', search: newState6.search });
            return newState6;
        case 'setConceptGroups':
            const newState1 = { ...state, search: { ...state.search, concepts: action.value, offset: 0 }, searchResponse: LoadingSearch(state.search, state.searchResponse) };
            state.search.concepts = action.value;
            dispatch()({ type: 'debounceSearch', search: newState1.search });
            return newState1;
        case 'setExcludeConceptGroups':
            const newStateE = {
                ...state, search: {
                    ...state.search, exclude_concepts: action.value, offset: 0
                }, searchResponse: LoadingSearch(state.search, state.searchResponse)
            };
            state.search.concepts = action.value;
            dispatch()({ type: 'debounceSearch', search: newStateE.search });
            return newStateE;
        case 'setRange':
            const newState = {
                ...state, search: {
                    ...state.search, ranges: { ...state.search.ranges, [action.field]: action.value }, offset: 0
                }, searchResponse: LoadingSearch(state.search, state.searchResponse)
            };
            dispatch()({ type: 'debounceSearch', search: newState.search });
            return newState;
        case 'setMolecule':
            (action.molecule as any).distance = state.search.molecule.distance;
            const newState5 = {
                ...state, search: {
                    ...state.search,
                    molecule: { ...action.molecule, distance: state.search.molecule.distance },
                    offset: 0,
                }, searchResponse: LoadingSearch(state.search, state.searchResponse)
            };
            dispatch()({ type: 'debounceSearch', search: newState5.search });
            return newState5;
        case 'setMoleculeBlock':
            const newState3 = {
                ...state, search: {
                    ...state.search,
                    molecule: { ...state.search.molecule, block: action.block },
                    offset: 0,
                }, searchResponse: LoadingSearch(state.search, state.searchResponse)
            };
            dispatch()({ type: 'debounceSearch', search: newState3.search });
            return newState3;
        case 'setMoleculeRelation':
            const newState2 = {
                ...state, search: {
                    ...state.search,
                    molecule: { ...state.search.molecule, relation: action.relation },
                    offset: 0
                },
                searchResponse: LoadingSearch(state.search, state.searchResponse),
            };
            dispatch()({ type: 'debounceSearch', search: newState2.search });
            return newState2;
        case 'setMoleculeDistance':
            try {
                const newStated = {
                    ...state,
                    search: {
                        ...state.search, q,
                        molecule: { ...state.search.molecule, distance: action.distance },
                        offset: 0,
                    },
                    searchResponse: LoadingSearch(state.search, state.searchResponse),
                };
                dispatch()({ type: 'debounceSearch', search: newStated.search });
                return newStated;

            } catch (e) { }
            return state;
        case 'setSearchQuery':
            const newState4 = {
                ...state, search: {
                    ...state.search, q: action.query, offset: 0
                }
            };
            if (window.location.pathname != "/" && window.location.pathname != ""
                && !window.location.pathname.startsWith('/catalog')
                && !window.location.pathname.startsWith('/advanced')
                && !window.location.pathname.startsWith('/sketcher')) {
                route(`/advanced?q=${action.query}`);
            }
            if (state.search.q && !action.query) {
                const location = getLocation();
                if (location == 'catalog'
                    || (location == 'advanced' && !state.search.concepts?.length
                        && !state.search.exclude_concepts?.length
                        && Object.keys(state.search.ranges || {}).filter(key => (state.search.ranges || {})[key]).length == 0)) {
                    return newState4;
                }
            }
            dispatch()({ type: 'debounceSearch', search: newState4.search });
            return newState4;
        case 'setPageSize':
            const newSearch7 = { ...state.search, limit: action.size, offset: 0 };
            const newState7 = {
                ...state, search: newSearch7, searchResponse: LoadingSearch(newSearch7, state.searchResponse),
                loadingHeight: state.searchResponse.data.length ? document.querySelector('.products-grid')?.clientHeight : state.loadingHeight,
            };
            dispatch()({ type: 'debounceSearch', search: newState7.search });
            return newState7;
        case 'setPage':
            const newState8 = {
                ...state,
                loadingHeight: state.searchResponse.data.length ? document.querySelector('.products-grid')?.clientHeight : state.loadingHeight,
                search: { ...state.search, offset: (action.page - 1) * state.search.limit }, searchResponse: LoadingSearch(state.search, state.searchResponse)
            };
            dispatch()({ type: 'debounceSearch', search: newState8.search });
            return newState8;
        case 'search.response':
            if (window.location.pathname != "/" && window.location.pathname != ""
                && !window.location.pathname.startsWith('/catalog')
                && !window.location.pathname.startsWith('/advanced')
                && !window.location.pathname.startsWith('/sketcher')) {
                route('/catalog?q=' + state.search.q);
            }
            action.response.query = state.search.q;
            console.log(state, action.response.data);
            for (const item of action.response.data) {
                if (item.molecule.other_names && typeof (item.molecule.other_names) == 'string') {
                    item.molecule.other_names = item.molecule.other_names.split('|');
                }
            }

            if (state.sketcher && state.sketcher.isEmpty() && action.response.data.length == 1 && isSketcher) {
                console.log('importing molecule');
                api.getById(action.response.data[0].molecule.code).then((data) => {
                    //dispatch({ type: 'setMolecule', molecule: { block: data.block, relation: '' } });
                    state.sketcher.importAsMol(data.block);
                });
            }

            /*if(state.search.q && window.location.pathname.startsWith('/sketcher')) {
                state = { ...state, search: { ...state.search, q: '' } };
            }*/

            return { ...state, stockReady: action.response.loading ? false : state.stockReady, searchResponse: action.response };
        case 'search.packs':
            return { ...state, packs: action.packs, stockReady: true };
        case 'search.packs.append':
            return { ...state, packs: [...state.packs, ...action.packs.sort((a, b) => a.price - b.price).filter(it => !state.packs.find(p => p.id == it.id))] };
        case 'logged':
            localStorage.setItem('token', action.token);
            localStorage.setItem('user', JSON.stringify(action.user));
            localStorage.setItem('data', JSON.stringify(action.data));
            addCustomStyles();

            const stateUpdate: any = {
                token: action.token,
                user: action.user,
            }

            for (const kind of ['cart', 'quote'] as ('cart' | 'quote')[]) {
                const cartSuffix = stateUpdate.user && !stateUpdate.user.punchout_id ? stateUpdate.user.id : '';
                if (localStorage.getItem('cart' + cartSuffix)) {
                    const cart = JSON.parse(localStorage.getItem(kind + cartSuffix) as any);
                    if (cart?.version == Cart.version) {
                        stateUpdate[kind] = cart;
                    }
                }
            }
            return { ...state, ...stateUpdate };
        case 'logout':
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('cart');
            localStorage.removeItem('quote');
            localStorage.removeItem('data');
            return { ... new Model() };
        case 'filter.clear':
            state.search.q = '';
            state.filter = {};
            break;
        case 'add':
            addToCartOrQuote(state, action, 'cart');
            console.log(state);
            console.log(action);

            action.packs.forEach(pack => {
                const { id } = pack.pack;
                const { quantity } = pack;

                const foundPack = state.packs.find(sp => sp.id === id);
                if (foundPack) {
                    if (foundPack.addedToCart && foundPack.quantity) {
                        foundPack.quantity = foundPack.quantity + quantity;
                    } else {
                        foundPack.addedToCart = true;
                        foundPack.quantity = quantity;
                    }
                }
            });



            break;
        case 'quote.add':
            addToCartOrQuote(state, action, 'quote');
            break;


        case 'remove':
            state[action.kind].items.forEach(it => it.packs = it.packs.filter(it => it.pack.id != action.id));
            state[action.kind].items = state[action.kind].items.filter(it => it.packs.length);

            localStorage.setItem(action.kind + getCartSuffix(state), JSON.stringify(state[action.kind]));

            break;
        case 'quantity':
            // update quantity
            state[action.kind].items
                .forEach(it => it.packs.filter(it => it.pack.id == action.id)
                    .forEach(it => it.quantity = action.quantity));
            // remove if quantity is 0
            // TODO: state.cart.items = state.cart.items.filter(it => it.quantity);

            localStorage.setItem(action.kind + getCartSuffix(state), JSON.stringify(state[action.kind]));
            break;
        case 'unit':
            state[action.kind].items
                .forEach(it => it.packs.filter(it => it.pack.id == action.id)
                    .forEach(it => it.pack.size = action.unit));

            localStorage.setItem(action.kind + getCartSuffix(state), JSON.stringify(state[action.kind]));
            break;
        case 'cart.clear':
            state.cart = { ...state.cart, items: [] };
            localStorage.setItem('cart' + getCartSuffix(state), JSON.stringify(state.cart));
            break;
        case 'quote.clear':
            state.quote = { ...state.quote, items: [] };
            localStorage.setItem('quote' + getCartSuffix(state), JSON.stringify(state.quote));
            break;
        case 'products':
            state.products = [...action.products];
            // fuse = new Fuse(state.products, { keys: ['name', 'description'], findAllMatches: true, threshold: 0.4 });
            break;
        case 'filter':
            state.filter = action.filter;
            break;
        case 'filter.in':
            state.filter[action.key] = state.filter[action.key] || [];
            if (action.value) {
                state.filter[action.key].push(action.option);
            } else {
                state.filter[action.key] = state.filter[action.key].filter(it => it !== action.option);
            }
            if (state.filter[action.key].length == 0) {
                delete state.filter[action.key];
            }
            break;
        case 'endanimate':
            state.cartClass = '';
            break;
        default:
            break;
    }
    return { ...state };
}


/*
export function search(state: Model) {
    return (state.search ? fuse.search(state.search).map(it => it.item) : state.products).filter(product => {
        for (const key in state.filter) {
            const value = state.filter[key];
            if (value == undefined) {
                continue;
            } else if (Array.isArray(value)) {
                if (value.includes(product[key])) {
                    continue;
                }
            } else if (value == product[key]) {
                continue;
            }
            return false;
        }
        return true;
    });
}*/

const hash = (str: any) => {
    if (typeof (str) !== 'string') {
        str = JSON.stringify(str);
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
};


// export const API_KEY = 'bd76d9b91d86f6e11c36ae4393b90828b7fd029ad9a8ed6847d7285785cbf534';

function getLocation(): 'catalog' | 'sketcher' | 'advanced' {
    if (location.pathname.startsWith('/sketcher')) {
        return 'sketcher';
    } else if (location.pathname.startsWith('/advanced')) {
        return 'advanced';
    } else {
        return 'catalog';
    }
}

export async function chemsearch(data: SearchConfig): Promise<SearchResult> {
    const options: Partial<SearchConfig> = JSON.parse(JSON.stringify(data));
    if (options.molecule?.block == "\n  MJ192100                      \n\n  0  0  0  0  0  0  0  0  0  0999 V2000\nM  END\n") {
        options.molecule.block = '';
    }
    console.log('search', options);
    if (!options.q && !options.molecule?.block && (
        //location.pathname.startsWith('/sketcher') || location.pathname.startsWith('/advanced')
        //&&
        !options.concepts?.length
        && !options.exclude_concepts?.length
        && Object.keys(options.ranges || {}).filter(key => (options.ranges || {})[key]).length == 0
    )) {
        console.log('search.option', options);
        return Promise.resolve({ total: 0, items: [], data: [], limit: 0, offset: 0, loading: false, query: '', took: 0 });
    }
    if (options.molecule && (!options.molecule.block || !options.molecule.relation)) {
        delete options.molecule;
    }

    const location = getLocation();

    if (location == 'sketcher') {
        if (!options.molecule?.block) {
            return Promise.resolve({ total: 0, items: [], data: [], limit: 0, offset: 0, loading: false, query: '', took: 0 });
        }
        delete options.concepts;
        delete options.exclude_concepts;
        delete options.ranges;
        options.q = '';

    } else if (location == 'advanced') {
        if (!options.q && (
            !options.concepts?.length
            && !options.exclude_concepts?.length
            && Object.keys(options.ranges || {}).filter(key => (options.ranges || {})[key]).length == 0
        )) {
            return Promise.resolve({ total: 0, items: [], data: [], limit: 0, offset: 0, loading: false, query: '', took: 0 });
        }
        delete options.molecule;
    } else {
        if (!options.q) {
            return Promise.resolve({ total: 0, items: [], data: [], limit: 0, offset: 0, loading: false, query: '', took: 0 });
        }
        delete options.molecule;
        delete options.concepts;
        delete options.exclude_concepts;
        delete options.ranges;
    }


    if (options.q) {
        options.q = options.q
            //.replace(/-/g, ' ')
            .replace(/\//g, ' ')
            //.replace(/\(/g, ' ')
            //.replace(/\)/g, ' ')
            .replace(/\./g, ' ')
            .toLowerCase();
    }
    const res = await fetch('https://dougdiscovery.com/api/v1/molecules/search', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(options)
    });
    return await res.json();
}


export function cartsearch(tokens: string[]): Promise<{ data: Molecule[] }> {
    return fetch('https://dougdiscovery.com/api/v1/molecules/cart-search', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ tokens })
    }).then(res => res.json());
}


export function getDetailsById(id: string): Promise<ChemicalDetails> {
    return fetch(`https://dougdiscovery.com/api/v1/chemicals/product/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    }).then(res => res.json());
}

export function getInventoryById(id: string, location?: string): Promise<Pack[]> {
    return fetch(`https://dougdiscovery.com/api/v1/chemicals/product/${id}/inventory${location ? '?location=' + location : ''}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    }).then(res => res.json())
        .catch(it => []);
}

export function getInventory(ids: string[], location?: string): Promise<Pack[]> {
    if (!ids.length) {
        return Promise.resolve([]);
    }
    return fetch(`https://dougdiscovery.com/api/v1/chemicals/inventory?ids=${ids.join(',')}${location ? '&location=' + location : ''}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    }).then(res => res.json());
}


export interface MoleculeOld {
    block: string;
    cas: string;
    catalog_number: string;
    concept_combination_codes: number[];
    count_list_of_atoms: number;
    exact_mw: number;
    formal_charge: number;
    formal_charge_neg: number;
    formal_charge_pos: number;
    full_name: string;
    largest_chain: number;
    largest_pi_chain: number;
    lipinskis_rule_of_five: number;
    list_of_atoms: string[];
    list_of_atoms_split_result_set: string[];
    matched_substructs: string[];
    mdl: string;
    molecular_formula: string[];
    num_aliphatic_carbocycles: number;
    num_aliphatic_heterocycles: number;
    num_aliphatic_rings: number;
    num_amide_bonds: number;
    num_aromatic_carbocycles: number;
    num_aromatic_heterocycles: number;
    num_aromatic_rings: number;
    num_hba: number;
    num_hbd: number;
    num_heavy_atoms: number;
    num_hetero_atoms: number;
    num_lipinski_hba: number;
    num_lipinski_hbd: number;
    num_rings: number;
    num_rotatable_bonds: number;
    num_saturated_carbocycles: number;
    num_saturated_rings: number;
    number_of_aliphatic_atoms: number;
    number_of_aliphatic_bonds: number;
    number_of_aromatic_atoms: number;
    number_of_aromatic_bonds: number;
    number_of_carbons: number;
    number_of_chiral_centers: number;
    number_of_cis_trans_bonds: number;
    number_of_connected_components: number;
    number_of_heteroatoms_in_aromatic_rings: number;
    number_of_hydrogens: number;
    number_of_unique_atoms: number;
    rdkit_molecule_inchi_code: string;
    rdkit_molecule_inchi_key: string;
    rotatable_bonds_count: number;
    slog_p: number;
    smiles: string;
    sp3_character: number;
    tokens: number[];
    topological_polar_surface_area: number;
    total_number_of_atoms: number;
    tpsa: number;
    type_of_atoms_reference: number;
}

export interface Datum {
    molecule: Molecule;
    score: number;
}

export interface SearchResult {
    data: Datum[];
    limit: number;
    offset: number;
    took: number;
    total: number;
    query: string;
    loading: boolean;
}




function getPackSku(pack: Pack) {
    return pack.sku || (pack.code + '-' + pack.size.replace(/\s/g, '').toUpperCase());
}

export interface Pack {
    id: number;
    code: string;
    size: string;
    price: number;
    sku?: string;
    availability: string;
    availability_reason: string;
    quantity?: number;
    addedToCart?: boolean;
    cartExistences?: number;
}

export interface ChemicalDetails {
    unit: string;
    number: string;
    name: string;
    cas?: any;
    mdl: string;
    smile: string;
    groups: number[];
    purity: number;
    state: string;
    appearance?: any;
    melting_freezing: string;
    other_info?: any;
    sds_link: string;
    classification: string;
    signal_word: string;
    pictograms: string;
    hazards: string;
    precautions: string;
    transport?: any;
    regulatory?: any;
    quantity: number;
    packs: Pack[];

}





export interface SalesOrder {
    abbrevtype: string;
    balsegstatus: string;
    billingaddress: string;
    billingstatus: string;
    createdby: string;
    createddate: string;
    currency: string;
    custbody10: string;
    custbody14: string;
    custbody_15699_exclude_from_ep_process: string;
    custbody_cat_approval_required: string;
    custbody_cat_approved: string;
    custbody_cat_approver: string;
    custbody_cat_be_dont_source_emails: string;
    custbody_cat_be_invinqueryignorefromcc: string;
    custbody_cat_bulk_email_inv_ap: string;
    custbody_cat_bulk_email_so_applies: string;
    custbody_cat_bulkemailtransactiontype: string;
    custbody_cat_contact_filtering: string;
    custbody_cat_depositamountmet: string;
    custbody_cat_elevated_approved: string;
    custbody_cat_elevated_required: string;
    custbody_cat_emailsent: string;
    custbody_cat_estimate_email_sent: string;
    custbody_cat_filemaker_exclude: string;
    custbody_cat_filemaker_exported: string;
    custbody_cat_filemaker_id: string;
    custbody_cat_filemaker_send: string;
    custbody_cat_inv_email_sent: string;
    custbody_cat_invoice_contact: string;
    custbody_cat_is_replacement_order: string;
    custbody_cat_isproformaorder: string;
    custbody_cat_proforma_approval_allowed: string;
    custbody_cat_proformaapproved: string;
    custbody_cat_proformadepositpercentage: string;
    custbody_cat_restriction_nomatch: string;
    custbody_cat_sendemail: string;
    custbody_cat_sent_to_vurbis: string;
    custbody_cat_shipnotify_contact: string;
    custbody_cat_shipping_total_updated: string;
    custbody_cat_so_contact: string;
    custbody_cat_so_contact_is_agent: string;
    custbody_cat_so_email_sent: string;
    custbody_cat_sold_to: string;
    custbody_cat_vat_exempt_order: string;
    custbody_erpff_p2p_auto_send_document: string;
    custbody_erpff_p2p_document_sent: string;
    custbody_fc_checked_shipping: string;
    custbody_fc_checkpunchout: string;
    custbody_fc_cmconfirmation: string;
    custbody_fc_custdeposit: string;
    custbody_fc_focreplacement: string;
    custbody_fc_senttob2b: string;
    custbody_fc_senttoexternal: string;
    custbody_fc_senttokardex: string;
    custbody_fc_shiptoemail: string;
    custbody_fc_taxcodechecked_salesorder: string;
    custbody_fc_ticktoclearfreeshipping: string;
    custbody_fc_vatexempt_statement: string;
    custbody_ff_br_exclude_transaction: string;
    custbody_report_timestamp: string;
    custbody_sent_to_pcloud: string;
    custbody_sii_article_61d: string;
    custbody_sii_article_72_73: string;
    custbody_sii_is_third_party: string;
    custbody_sii_not_reported_in_time: string;
    custbody_stc_amount_after_discount: string;
    custbody_stc_tax_after_discount: string;
    custbody_stc_total_after_discount: string;
    custbodycat_second_inv_email_sent: string;
    customtype: string;
    daysopen: string;
    entity: string;
    estgrossprofit: string;
    estgrossprofitpercent: string;
    exchangerate: string;
    foreigntotal: string;
    id: string;
    includeinforecast: string;
    isfinchrg: string;
    isreversal: string;
    lastmodifiedby: string;
    lastmodifieddate: string;
    links: any[];
    message: string;
    nextbilldate: string;
    nexus: string;
    number: string;
    ordpicked: string;
    otherrefnum: string;
    outsourced: string;
    paymenthold: string;
    posting: string;
    postingperiod: string;
    printedpickingticket: string;
    recordtype: string;
    shipcomplete: string;
    shipdate: string;
    shippingaddress: string;
    status: string;
    terms: string;
    totalcostestimate: string;
    trandate: string;
    trandisplayname: string;
    tranid: string;
    transactionnumber: string;
    type: string;
    typebaseddocumentnumber: string;
    userevenuearrangement: string;
    visibletocustomer: string;
    void: string;
    voided: string;
    actualshipdate: string;
    closedate: string;
    custbody_cat_shipping_cost_total: string;
    custbody_nexus_notc: string;
    linkedtrackingnumberlist: string;
    shipcarrier: string;
    custbody_cat_filemaker_tracking_nums: string;
}

export interface Molecule {
    other_names?: string | string[];
    sds: {
        custrecord_sdslink_en?: string,
        custrecord_sdslink_dk?: string,
        custrecord_sdslink_nl?: string,
        custrecord_sdslink_fi?: string,
        custrecord_sdslink_fr?: string,
        custrecord_sdslink_de?: string,
        custrecord_sdslink_it?: string,
        custrecord_sdslink_es?: string,
        custrecord_sdslink_sv?: string,
        custrecord_sdslink_no?: string,
        custrecord_fc_unspsc_fccode?: string;
    }
    properties?: { [key: string]: any };
    tokens: number[];
    block: string;
    name: string;
    cas?: string;
    purity?: number;
    code: string;
    smiles: string;
    iupac_name?: string;
    molecular_weight?: number;
    asymetric_atoms?: number;
    log_p?: number;
    h_bond_acceptors?: number;
    h_bond_donors?: number;
    data: { [key: string]: string };
}


export module netsuite {

    export interface Estimate {
        _eml_nkey_: string
        address_country_state_map: string
        balance: string
        balreadyrefunded: string
        baserecordtype: string
        billaddress: string
        billaddresslist: string
        billingaddress2_set: string
        billingaddress_key: string
        billingaddress_text: string
        billingaddress_type: string
        billisresidential: string
        canhavestackable: string
        carrier: string
        checkcommitted: string
        companyid: string
        createddate: string
        credholdentity: string
        credholdoverride: string
        currency: string
        currencyname: string
        currencyprecision: string
        currencysymbol: string
        custbody10: string
        custbody14: string
        custbody15: string
        custbody_15699_exclude_from_ep_process: string
        custbody_atlas_exist_cust_hdn: string
        custbody_atlas_new_cust_hdn: string
        custbody_atlas_no_hdn: string
        custbody_atlas_yes_hdn: string
        custbody_auto_send_document_nonstore: string
        custbody_bs_ddr_donot_reprocess: string
        custbody_cat_approval_required: string
        custbody_cat_approved: string
        custbody_cat_be_dont_source_emails: string
        custbody_cat_be_invinqueryignorefromcc: string
        custbody_cat_be_send_bulk_sales_rep: string
        custbody_cat_bulk_email_inv_ap: string
        custbody_cat_bulk_email_so_applies: string
        custbody_cat_bulkemailtransactiontype: string
        custbody_cat_contact_filtering: string
        custbody_cat_customer_comments: string
        custbody_cat_depositamountmet: string
        custbody_cat_elevated_approved: string
        custbody_cat_elevated_required: string
        custbody_cat_emailsent: string
        custbody_cat_estimate_email_sent: string
        custbody_cat_filemaker: string
        custbody_cat_filemaker_exclude: string
        custbody_cat_filemaker_exported: string
        custbody_cat_inv_email_sent: string
        custbody_cat_invoice_contact: string
        custbody_cat_is_replacement_order: string
        custbody_cat_isproformacustomer: string
        custbody_cat_isproformaorder: string
        custbody_cat_proforma_approval_allowed: string
        custbody_cat_proformaapproved: string
        custbody_cat_proformadepositpercentage: string
        custbody_cat_restriction_nomatch: string
        custbody_cat_sendemail: string
        custbody_cat_sent_to_vurbis: string
        custbody_cat_shipping_cost_total: string
        custbody_cat_shipping_total_updated: string
        custbody_cat_so_contact: string
        custbody_cat_so_contact_is_agent: string
        custbody_cat_so_email_sent: string
        custbody_cat_sold_to: string
        custbody_cat_subcountry: string
        custbody_cat_vat_exempt_order: string
        custbody_emea_transaction_type: string
        custbody_erpff_p2p_auto_send_document: string
        custbody_erpff_p2p_document_sent: string
        custbody_esc_created_date: string
        custbody_esc_last_modified_date: string
        custbody_fc_approvalreason: string
        custbody_fc_checked_shipping: string
        custbody_fc_checkpunchout: string
        custbody_fc_cmconfirmation: string
        custbody_fc_custdeposit: string
        custbody_fc_customercomments: string
        custbody_fc_focreplacement: string
        custbody_fc_senttob2b: string
        custbody_fc_senttoexternal: string
        custbody_fc_senttokardex: string
        custbody_fc_taxcodechecked_salesorder: string
        custbody_fc_ticktoclearfreeshipping: string
        custbody_fc_ticktoclearpurchasing: string
        custbody_ff_br_exclude_transaction: string
        custbody_report_timestamp: string
        custbody_sent_to_pcloud: string
        custbody_sii_article_61d: string
        custbody_sii_article_72_73: string
        custbody_sii_is_third_party: string
        custbody_sii_not_reported_in_time: string
        custbody_sit_subsidiary_address: string
        custbodycat_second_inv_email_sent: string
        customform: string
        datedriven: string
        dbstrantype: string
        discounttotal: string
        disctax1amt: string
        duedate: string
        duedays: string
        edition: string
        entity: string
        entity_nexus_country: string
        entityfieldname: string
        entityname: string
        entitynexus: string
        entitystatus: string
        entryformquerystring: string
        estgrossprofit: string
        estgrossprofitpercent: string
        exchangerate: string
        expectedclosedate: string
        generatetranidonsave: string
        hasfedexfreightservice: string
        haslines: string
        id: string
        includeinforecast: string
        installmentcount: string
        inventorydetailuitype: string
        isbasecurrency: string
        isinstallment: string
        isonlinetransaction: string
        item: EstimateItem
        item_total: string
        itemshippingcostfxrate: string
        lastmodifieddate: string
        linked: string
        linkedclosedperioddiscounts: string
        linkedrevrecje: string
        location: string
        locationusesbins: string
        manualcredithold: string
        message: string
        mindays: string
        nexus: string
        nexus_country: string
        nlapiCC: string
        nldept: string
        nlloc: string
        nlrole: string
        nlsub: string
        nluser: string
        nsapiCT: string
        ntype: string
        oldrevenuecommitment: string
        origcurrency: string
        origexchangerate: string
        originalnexus: string
        originalnexuscountry: string
        origtotal: string
        origtotal2: string
        overallbalance: string
        overallunbilledorders: string
        persistedterms: string
        prevdate: string
        primarycurrency: string
        primarycurrencyfxrate: string
        probability: string
        recordcreatedby: string
        recordcreateddate: string
        rectype_138_979_maxnkey: string
        rectype_531_2730_maxnkey: string
        rectype_542_2790_maxnkey: string
        rectype_553_2856_maxnkey: string
        rectype_981_4929_maxnkey: string
        revchargeamt: string
        shipaddress: string
        shipaddresslist: string
        shipcountry: string
        shipdate: string
        shipisresidential: string
        shipitemhasfreeshippingitems: string
        shipoverride: string
        shippingaddress2_set: string
        shippingaddress_key: string
        shippingaddress_text: string
        shippingaddress_type: string
        shippingcostoverridden: string
        shipzip: string
        status: string
        statusRef: string
        subsidiary: string
        subtotal: string
        sys_id: string
        tax_affecting_address_fields_before_recalc: string
        taxfractionunit: string
        taxrounding: string
        taxroundinglevel: string
        taxtotal: string
        templatestored: string
        terms: string
        tobeemailed: string
        tobefaxed: string
        tobeprinted: string
        total: string
        totalcostestimate: string
        trandate: string
        tranid: string
        transactionnumber: string
        type: 'estimate'
        unbilledorders: string
        vatregnum: string
        version: string
        visibletocustomer: string
        voided: string
        warnnexuschange: string
        weekendpreference: string
        wfinstances: string
    }

    export interface EstimateItem {
        items: EstimateItem2[]
    }

    export interface EstimateItem2 {
        amount: string
        amounthasbeenset: string
        backordered: string
        billvariancestatus: any
        billvariancestatusallbook: string
        binitem: string
        costestimate: string
        costestimaterate: string
        costestimatetype: string
        costestimatetype_display: string
        costestimatetypelist: string
        custcol4: string
        custcol5: any
        custcol5_display: any
        custcol_2663_companyname: any
        custcol_2663_firstname: any
        custcol_2663_isperson: any
        custcol_2663_lastname: any
        custcol_5892_eutriangulation: string
        custcol_9572_cr_entitybank_format: any
        custcol_9572_cr_entitybank_sub: any
        custcol_9572_custref_file_format: any
        custcol_9572_dd_file_format: any
        custcol_cat_bomstocklevels: string
        custcol_cat_commodity_code: string
        custcol_cat_custom_expected_ship_date: any
        custcol_cat_customershippingnumber: any
        custcol_cat_inventory_number_coos: any
        custcol_cat_item_display_name: string
        custcol_cat_itemtransportrequirements: any
        custcol_cat_productalerts: any
        custcol_cat_quantity_replaced: string
        custcol_cat_restriction_information: any
        custcol_cat_safety_data_url: string
        custcol_cat_shippingcost: string
        custcol_cat_shippingmethod: string
        custcol_cat_size_test: any
        custcol_cat_sizeunit: string
        custcol_cat_so_requiredapproval: string
        custcol_cat_vurbis_line_number: any
        custcol_counterparty_vat: any
        custcol_country_of_origin_code: any
        custcol_country_of_origin_name: any
        custcol_emea_country_of_origin: any
        custcol_emea_country_of_origin_display: any
        custcol_emirate: any
        custcol_establishment_code: any
        custcol_fc_a: string
        custcol_fc_appreasons: any
        custcol_fc_chinastock: any
        custcol_fc_columnpo2: any
        custcol_fc_expectedshipdate: any
        custcol_fc_germanystock: any
        custcol_fc_size: string
        custcol_fc_unit: any
        custcol_fc_unit_display: any
        custcol_ff_sc_vatex_reason: any
        custcol_nature_of_transaction_codes: any
        custcol_sii_exempt_line_details: any
        custcol_statistical_procedure_sale: any
        custcol_statistical_procedure_sale_display: any
        custcol_statistical_value: any
        custcol_statistical_value_base_curr: string
        custcol_str_stat_value: any
        custcol_str_stat_value_base_curr: any
        ddistrib: string
        description: string
        discline: any
        excludefromraterequest: string
        expectedshipdate: any
        fulfillable: string
        generateaccruals: string
        grossamt: string
        groupsetup: any
        id: string
        includegroupwrapper: string
        ingroup: any
        initoqpbucket: any
        initquantity: string
        inventorydetailavail: string
        isnoninventory: string
        isnumbered: string
        isserial: string
        item: string
        item_display: string
        itemhandlingcost: any
        itemshippingcost: any
        itemsubtype: any
        itemtype: string
        line: string
        linenumber: any
        lineuniquekey: string
        locationusesbins: string
        mandatorytaxcode: string
        marginal: string
        matrixtype: any
        minqty: any
        noprint: string
        oldexpectedshipdate: any
        olditemid: string
        oldquantity: string
        onorder: string
        options: any
        oqpbucket: any
        price: string
        price_display: string
        pricelevels: string
        printitems: string
        quantity: string
        quantityavailable: string
        rate: string
        rateschedule: string
        refamt: string
        reorder: string
        reversecharge: string
        revrecdeferred: any
        sys_id: string
        sys_parentid: string
        tax1amt: string
        taxcode: string
        taxcode_display: string
        taxrate1: string
        unitconversionrate: any
        units: any
        units_display: any
        unitslist: any
        weightinlb: string
    }


    export interface Order {
        type: 'salesord'
        links: Link[]
        altShippingCost: number
        billAddress: string
        billingAddress: BillingAddress
        billingAddress_text: string
        canBeUnapproved: boolean
        createdDate: string
        currency: Currency
        custbody10: boolean
        custbody_15699_exclude_from_ep_process: boolean
        custbody_atlas_exist_cust_hdn: CustbodyAtlasExistCustHdn
        custbody_atlas_new_cust_hdn: CustbodyAtlasNewCustHdn
        custbody_atlas_no_hdn: CustbodyAtlasNoHdn
        custbody_atlas_yes_hdn: CustbodyAtlasYesHdn
        custbody_auto_send_document_nonstore: boolean
        custbody_cat_approval_required: boolean
        custbody_cat_approved: boolean
        custbody_cat_approver: CustbodyCatApprover
        custbody_cat_be_dont_source_emails: boolean
        custbody_cat_be_invinqueryignorefromcc: boolean
        custbody_cat_be_send_bulk_sales_rep: boolean
        custbody_cat_bulk_email_inv_applies: any
        custbody_cat_bulk_email_so_applies: boolean
        custbody_cat_carrier_name: any
        custbody_cat_customer_comments: any
        custbody_cat_depositamountmet: boolean
        custbody_cat_elevated_approved: boolean
        custbody_cat_elevated_required: boolean
        custbody_cat_emailsent: boolean
        custbody_cat_estimate_email_sent: boolean
        custbody_cat_filemaker_id: string
        custbody_cat_filemaker_exclude: boolean
        custbody_cat_filemaker_exported: boolean
        custbody_cat_filemaker_send: boolean
        custbody_cat_filemaker_tracking_nums: any
        custbody_cat_inv_email_sent: boolean
        custbody_cat_invoice_contact: CustbodyCatInvoiceContact
        custbody_cat_shipnotify_contact: CustbodyCatShipnotifyContact
        custbody_cat_is_replacement_order: boolean
        custbody_cat_isproformacustomer: boolean
        custbody_cat_isproformaorder: boolean
        custbody_cat_proforma_approval_allowed: boolean
        custbody_cat_proformaapproved: boolean
        custbody_cat_proformaemailsent: any
        custbody_cat_restriction_nomatch: boolean
        custbody_cat_sendemail: boolean
        custbody_cat_sent_to_vurbis: boolean
        custbody_cat_so_contact: CustbodyCatSoContact
        custbody_cat_so_contact_is_agent: boolean
        custbody_cat_so_email_sent: boolean
        custbody_cat_sold_to: CustbodyCatSoldTo
        custbody_cat_subcountry: any
        custbody_cat_vat_exempt_order: boolean
        custbody_cat_ci_tracking_link: any
        custbody_cat_tracking_link: any
        custbody_delivery_terms: any
        custbody_emea_transaction_type: string
        custbody_erpff_p2p_auto_send_document: boolean
        custbody_erpff_p2p_document_sent: boolean
        custbody_esc_created_date: string
        custbody_esc_last_modified_date: string
        custbody_fc_checkedpunchoutorder: any
        custbody_fc_senttokardex: boolean
        custbody_fc_shiptoemail: string
        custbody_fc_shiptophone: any
        custbody_fc_taxcodechecked_salesorder: boolean
        custbody_ff_br_exclude_transaction: boolean
        custbody_ff_sc_ital_valior_e_doc_sent: any
        custbody_ff_sc_validator_e_doc_sent: any
        custbody_hitc_maps_ship_latitude: any
        custbody_hitc_maps_ship_longitude: any
        custbody_nexus_notc: CustbodyNexusNotc
        custbody_notc: any
        custbody_report_timestamp: string
        custbody_sent_to_pcloud: boolean
        custbody_sii_article_61d: boolean
        custbody_sii_article_72_73: boolean
        custbody_sales_invoice_notes: any
        custbody_sii_is_third_party: boolean
        custbody_sii_not_reported_in_time: boolean
        custbody_stc_amount_after_discount: number
        custbody_stc_tax_after_discount: number
        custbody_stc_total_after_discount: number
        customForm: CustomForm
        entity: Entity
        estGrossProfit: number
        estGrossProfitPercent: number
        exchangeRate: number
        forInvoiceGrouping: boolean
        id: string
        item: Item
        lastModifiedDate: string
        linkedTrackingNumbers: string
        location: Location3
        message: string
        needsPick: boolean
        orderStatus: OrderStatus
        otherRefNum: string
        prevDate: string
        salesEffectiveDate: string
        shipAddress: string
        shipAddressList: ShipAddressList
        shipComplete: boolean
        shipDate: string
        shipIsResidential: boolean
        shipMethod: ShipMethod
        shipOverride: boolean
        shippingAddress: ShippingAddress
        shippingAddress_text: string
        shippingCost: number
        shippingCostOverridden: boolean
        status: Status
        subsidiary: Subsidiary
        subtotal: number
        suppressUserEventsAndEmails: string
        terms: Terms
        toBeEmailed: boolean
        toBeFaxed: boolean
        toBePrinted: boolean
        total: number
        totalCostEstimate: number
        tranDate: string
        tranId: string
        updateDropshipOrderQty: string
        webStore: string
    }

    export interface Link {
        rel: string
        href: string
    }

    export interface BillingAddress {
        addr1: string
        addr2: any
        attention: any
        addressee: string
        addrText: string
        city: string
        state: any
        country: Country
        override: boolean
        zip: string
    }

    export interface Country {
        id: string
    }

    export interface Currency {
        refName: string
        id: string
    }

    export interface CustbodyAtlasExistCustHdn {
        refName: string
        id: string
    }

    export interface CustbodyAtlasNewCustHdn {
        refName: string
        id: string
    }

    export interface CustbodyAtlasNoHdn {
        refName: string
        id: string
    }

    export interface CustbodyAtlasYesHdn {
        refName: string
        id: string
    }

    export interface CustbodyCatApprover {
        refName: string
        id: string
    }

    export interface CustbodyCatInvoiceContact {
        refName: string
        id: string
    }

    export interface CustbodyCatShipnotifyContact {
        refName: string
        id: string
    }

    export interface CustbodyCatSoContact {
        refName: string
        id: string
    }

    export interface CustbodyCatSoldTo {
        refName: string
        id: string
    }

    export interface CustbodyNexusNotc {
        refName: string
        id: string
    }

    export interface CustomForm {
        refName: string
        id: string
    }

    export interface Entity {
        refName: string
        id: string
    }

    export interface Item {
        links: Link2[]
        items: Item2[]
        totalResults: number
    }

    export interface Link2 {
        rel: string
        href: string
    }

    export interface Item2 {
        links: Link3[]
        amount: number
        commitmentFirm: boolean
        costEstimate: number
        costEstimateRate: number
        costEstimateType: CostEstimateType
        createdPo: any
        createPo: any
        createWo: boolean
        custcol_2663_isperson: boolean
        custcol_cat_bomstocklevels: string
        custcol_cat_item_display_name: string
        custcol_cat_safety_data_url: string
        custcol_cat_sizeunit: any
        custcol_cat_so_requiredapproval: boolean
        custcol_fc_size: string
        custcol_statistical_value_base_curr: number
        custcol_cat_shippingmethodname: string
        custcol_cat_shippingmethod: CustcolCatShippingmethod
        custcol_fc_expectedshipdate: string
        description: string
        excludeFromRateRequest: any
        expectedShipDate: string
        inventoryDetail: InventoryDetail
        isClosed: boolean
        isOpen: boolean
        item: Item4
        itemType: ItemType
        line: number
        linked: boolean
        location: Location2
        marginal: boolean
        orderAllocationStrategy: OrderAllocationStrategy
        poRate: number
        poVendor: PoVendor
        price: Price
        printItems: boolean
        quantity: number
        quantityAllocated: number
        quantityAvailable: number
        quantityBilled: number
        quantityCommitted: number
        quantityFulfilled: number
        rate: number
        requestedDate: string
        vendorList: any
    }

    export interface Link3 {
        rel: string
        href: string
    }

    export interface CostEstimateType {
        refName: string
        id: string
    }

    export interface CustcolCatShippingmethod {
        refName: string
        id: string
    }

    export interface InventoryDetail {
        links: Link4[]
        customForm: CustomForm2
        ignoreqtyvalidation: boolean
        inventoryassignment: Inventoryassignment
        item: Item3
        itemDescription: string
        location: Location
        quantity: number
        toLocation: ToLocation
    }

    export interface Link4 {
        rel: string
        href: string
    }

    export interface CustomForm2 {
        id: string
    }

    export interface Inventoryassignment {
        links: Link5[]
        items: any[]
        totalResults: number
    }

    export interface Link5 {
        rel: string
        href: string
    }

    export interface Item3 {
        id: string
    }

    export interface Location {
        id: string
    }

    export interface ToLocation {
        id: string
    }

    export interface Item4 {
        refName: string
        id: string
    }

    export interface ItemType {
        refName: string
        id: string
    }

    export interface Location2 {
        id: string
    }

    export interface OrderAllocationStrategy {
        refName: string
        id: string
    }

    export interface PoVendor {
        refName: string
        id: string
    }

    export interface Price {
        refName: string
        id: string
    }

    export interface Location3 {
        id: string
    }

    export interface OrderStatus {
        refName: string
        id: string
    }

    export interface ShipAddressList {
        refName: string
        id: string
    }

    export interface ShipMethod {
        refName: string
        id: string
    }

    export interface ShippingAddress {
        addr1: string
        addr2: any
        attention: any
        addressee: string
        addrText: string
        city: string
        state: any
        country: Country2
        override: boolean
        zip: string
    }

    export interface Country2 {
        id: string
    }

    export interface Status {
        refName: string
        id: string
    }

    export interface Subsidiary {
        refName: string
        id: string
    }

    export interface Terms {
        refName: string
        id: string
    }

}


function addCustomStyles() {
    try {
        const dataString = localStorage.getItem('data') || '';
        const data = JSON.parse(dataString);
        if (data.styles) {
            const style = document.createElement('style');
            style.innerHTML = data.styles;
            document.head.appendChild(style);
        }
    } catch (e) {
        console.warn(e);
    }
}

addCustomStyles();
