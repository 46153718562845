import { OrderFormItem } from "./cart";
import { AddressOverview, CartItem, Molecule, netsuite, QueryData, SalesOrder, SalesOrderOverview } from "./model";



export const base = 'https://dougdiscovery.com/api/v1/chemicals';


export interface User {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    company: string;
    companyname: string;
    currency: string;
    pricelevel: string;
    cus: string;
    punchout_id?: string;
    default_billing?: number;
    default_shipping?: number;
    tax_rate?: number;
    catalog_only?: boolean;
    subsidiary: string;
}
export interface LoginResponse {
    token: string;
    user: User;
    data: any;
}

export async function login(email: string, password: string, cus?: string, code?: string): Promise<{
    Logged?: LoginResponse,
    SelectCustomer?: { name: string, cus: string }[]
} | 'EmailPasswordReset'> {

    const body = {
        email,
        password,
        cus: cus || undefined,
        code: code || undefined
    };

    const res = await (await fetch(base + '/login', {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors'
    })).text();

    try {
        return JSON.parse(res);
    } catch (e) {
        throw new Error(res);
    }
}

export async function forgotPassword(email: string): Promise<{}> {

    const body = {
        email,
    };

    const res = await (await fetch(base + '/forgot-password', {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors'
    })).text();

    try {
        return JSON.parse(res);
    } catch (e) {
        throw new Error(res);
    }
}


export async function resetPassword(token: string, password: string): Promise<{}> {

    const body = {
        token,
        password,
    };

    const res = await (await fetch(base + '/reset-password', {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors'
    })).text();

    try {
        return JSON.parse(res);
    } catch (e) {
        throw new Error(res);
    }
}


export async function punchoutLogin(punchoutId: string): Promise<LoginResponse> {


    const res = await (await fetch(base + '/punchout/login', {
        body: JSON.stringify({ punchout_id: punchoutId }),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors'
    })).text();

    try {
        return JSON.parse(res);
    } catch (e) {
        throw new Error(res);
    }
}


export interface CartResponse {
    message: string;
    url: string;
    data: { [key: string]: string },
}

export async function punchoutCart(data: { items: CartItem[], currency: string }): Promise<CartResponse> {


    const res = await (await fetch(base + '/punchout/cart', {
        body: JSON.stringify({ cart: { Chems: data }, custom: {} }),
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
        },
    })).text();

    try {
        return JSON.parse(res);
    } catch (e) {
        throw new Error(res);
    }
}


export async function register(form: RegisterForm): Promise<LoginResponse> {
    const user = await (await fetch('/users', { method: 'POST', body: JSON.stringify(form) })).json();
    user.id = user._id;
    return { token: user._id, user, data: {} }
}

export async function registerNewAccount(form: RegisterForm): Promise<void> {
    const user = await (await fetch(base + '/register', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(form)
    })).json();
}

export async function updateUser(user: User): Promise<User> {
    return await (await fetch(base + '/me', {
        method: 'PUT', body: JSON.stringify(user), headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })).json();
}

export async function getOrders(): Promise<QueryData<SalesOrderOverview>> {
    return await (await fetch(base + '/orders', {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })).json();
}


export async function getDraftOrders(): Promise<QueryData<SalesOrderOverview>> {
    const res = await (await fetch(base + '/draft-orders', {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })).json();
    return {
        items: res.data,
    }
}

export async function getOrder(id: string): Promise<netsuite.Order> {
    const res = await (await fetch(base + '/orders/' + id, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })).json();
    if (res.items) {
        res.items.forEach((item: any) => {
            if (!item.sku.startsWith('F')) {
                item.sku = 'F' + item.sku;
            }
        });
    }
    return res;
}

export async function getQuotes(): Promise<QueryData<SalesOrderOverview>> {
    return await (await fetch(base + '/quotes', {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })).json();
}

export async function getQuote(id: string): Promise<any> {
    return await (await fetch(base + '/quotes/' + id, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })).json();
}

export async function approveOrder(id: string) {
    return await (await fetch(base + '/draft-orders/' + id + '/approve', {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
        }
    })).json();
}

export interface NewOrder {
    items: OrderFormItem[],
    currency: string,
    billing: any,
    shipping: any,
    po: string;
    comments: string;
    status?: string;
}
export async function placeOrder(newOrder: NewOrder): Promise<SalesOrder> {
    newOrder.shipping.city = newOrder.shipping.city || '';
    newOrder.shipping.postal_code = newOrder.shipping.postal_code || '';
    newOrder.billing.city = newOrder.billing.city || '';
    newOrder.billing.postal_code = newOrder.billing.postal_code || '';

    console.log(newOrder);
    // return Promise.reject({} as any);
    return await (await fetch(base + '/orders', {
        method: 'POST',
        body: JSON.stringify(newOrder),
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
        },
    })).json();
}

export async function placeQuote(newOrder: NewOrder): Promise<SalesOrder> {
    newOrder.shipping.city = newOrder.shipping.city || '';
    newOrder.shipping.postal_code = newOrder.shipping.postal_code || '';
    newOrder.billing.city = newOrder.billing.city || '';
    newOrder.billing.postal_code = newOrder.billing.postal_code || '';

    console.log(newOrder);
    // return Promise.reject({} as any);
    return await (await fetch(base + '/quotes', {
        method: 'POST',
        body: JSON.stringify(newOrder),
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
        },
    })).json();
}

export async function getAddresses(): Promise<AddressOverview[]> {
    return await (await fetch(base + '/addresses', {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        // mode: 'no-cors'

    })).json();
}


export async function getById(id: string): Promise<Molecule> {
    const res = await fetch('https://dougdiscovery.com/api/v1/molecules/' + id, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    }).then(res => res.json());
    if (res.other_names && typeof res.other_names === 'string') {
        res.other_names = res.other_names.split('|');
    }
    return res;
}

export interface NetsuiteAddress {
    id: number;
    attention: string;
    addressee: string;
    addr1: string;
    addr2: string;
    addrphone: string;
    addrtext: string;
    city: string;
    country: string;
    state?: any;
    zip: string;
}


export interface Address {
    street: string[];
    city: string;
    state: string;
    postalCode: string;
    country: string;
}

export interface RegisterForm {
    title?: string;
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    phone: string;
    company: string;
    department?: string;
    billing: Address;
    shipping: Address;
    comments?: string;
    customerId?: string;
}

export function getMoleculeSvg(id: string): string {
    return `https://dougdiscovery.com/api/v1/molecules/${id}.svg`;
}




export interface NewSalesOrderItem {
    quantity: number;
    item: string;
    location: number;
    rate: number;
    taxcode?: number;
}

export interface NewSalesOrder {
    otherrefnum: string,
    shipaddresslist: string;
    billaddresslist: string;
    // #[serde(rename = "custbody_cat_invoice_contact")]
    //custbody_cat_invoice_contact: RecordRef,
    subsidiary: number; // 8
    location: number;   // 4
    // customer info
    entity: number; // 25
    custbody_cat_sold_to: number;
    item: { items: NewSalesOrderItem[] };
    custbody_cat_vat_exempt_order: boolean;

    custbody_cat_invoice_contact?: number;
    custbody_cat_so_contact?: number;
    custbody_cat_shipnotify_contact?: number;
    custbody_fc_shiptoemail?: string;

    custbody_fc_checkedpunchoutorder: boolean;
    custbody_fc_checkpunchout: boolean;
    /// SALES ORDER EMAIL SENT: Prevents multiple send of Sales Order confirmations.
    custbody_cat_so_email_sent: boolean;
    /// BTP (jimi) comments field
    // custbody1: Option<String>,
    custbody_fc_punchoutnotes?: string;
    shippingtaxcode?: number;
    custbody_fc_vatexempt_statement?: string;
    attachments: any[],
}

export async function requestSDS(code: string, email: any) {
    return await (await fetch(base + '/request-sds', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({ code, email })
    })).json();

}

export async function deleteDraft(gid: string) {


}
