import { html } from 'htm/preact';
import { render } from 'preact';
import { useContext, useEffect, useReducer, useRef, useState } from 'preact/hooks';
import { route, Route, Router, RouterOnChangeArgs } from 'preact-router';
// import { Link } from 'preact-router//match';
import { createContext } from 'preact';
import { Model, RootReducer, Product, CartItem, Cart, Action, Chemical, Molecule, getInventory, SalesOrder, netsuite } from './model';
import { conceptBlocks, demo } from './data';
import { Advanced, Catalog, Sketcher } from './catalog';
// import { useDebounce } from './utils';
import { Details } from './details';
import { CartView, currencySymbol, QuoteView } from './cart';
import * as api from './api';
import { Admin } from './admin';
import * as commerce from './commerce';
import { PrivacyPolicyPage } from './privacy-policy';
import { TermsOfSalePage } from './terms-of-sale';
import { TermsOfServiceEUPage } from './terms-of-service-eu';
import { ModernSlaveryStatementPage } from './modern-slavery-statement';
import { ForgotPassword, ResetPassword } from './reset-password';
import { Landing } from './landing';
import { getCurrency } from './model';

import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

import Register from './components/Register';
import Login from './components/Login';
import Account from './components/Account';

import ConceptSearch from './components/ConceptSearch';
import CountrySelect from './components/CountrySelect';

declare var require: any;

export const DEFAULT_STATE = new Model();

const StoreContext = createContext({
    state: DEFAULT_STATE,      // Your default state.
    dispatch: (action: Action) => { },       // Stubbed. Will be replaced.
});


export interface Ctx {
    state: Model;
    dispatch: (action: Action) => void;
}

export const ctx = () => useContext(StoreContext)

const punchoutId = window.location.search
    .substring(1)
    .split('&')
    .map(pair => pair.split('='))
    .reduce(
        (p, n) => {
            p[n[0]] = decodeURIComponent(n[1]);
            return p;
        },
        {} as any,
    ).punchout || localStorage.getItem('punchoutId');

if (punchoutId) {
    localStorage.setItem('punchoutId', punchoutId);
}

function isUuid(str: string) {
    return str.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);
}

function App() {

    const [state, dispatch] = useReducer(RootReducer, DEFAULT_STATE);
    (window as any).dispatch = dispatch;
    const store = { state, dispatch };


    let [styles, setStyles] = useState('');


    const handleRoute = (e: RouterOnChangeArgs) => {
        console.log('ROUTE', e);
        let styles = '';
        if (e.url.startsWith('/account')) {
            styles += 'account customer-account-index page-layout-2columns-left';
        } else if (e.url.startsWith('/catalog')) {
            styles += 'page-products page-with-filter catalogsearch-result-index page-layout-2columns-left';
        } else if (e.url.startsWith('/p/')) {
            styles += 'page-product-configurable catalog-product-view product-benzyl-phenyl-ether page-layout-1column';
        } else if (e.url.startsWith('/cart')) {
            styles += 'checkout-cart-index page-layout-1column';
        }

        let url = e.url;
        const last = url.split('/').pop();
        if (last && isUuid(last)) {
            url = url.replace(last, 'details');
        }

        styles += ' route' + (url || 'home').replace(/\//g, '-');
        setStyles(styles);
    };


    if (!punchoutId && false) {
    } else {


        useEffect(() => {
            dispatch({ type: 'products', products: demo.products });
            //fetch('/api/v1/products')
            //  .then(res => res.json())
            //     .then(dispatch());
        }, []);
        return html`<${StoreContext.Provider} value=${store}>
<div class="page-wrapper ${styles}">
<${Header}/>
    <${Router} onChange=${handleRoute}>
        <${Main} default />
        <${Admin} path="/admin/:part*" />
        <${Login} path="/login" />
        <${ResetPassword} path="/reset-password/:token" />
        <${ForgotPassword} path="/forgot-password" />
        <${Register} path="/register" />
        <${Catalog} path="/catalog" state=${state} dispatch=${dispatch}/>
        <${Sketcher} path="/sketcher" state=${state} dispatch=${dispatch} />
        <${Advanced} path="/advanced" state=${state} dispatch=${dispatch} />
        <${ConceptSearch} path="/concept" />
        <${Account} path="/account/:q*" />
        <${CartView} path="/cart" />
        <${QuoteView} path="/quote" />
        <${Details} path="/p/:id" />
        <${Punchout} path="/punchout/:id" />
        <${PrivacyPolicyPage} path="/privacy-policy" />
        <${TermsOfSalePage} path="/terms-of-sale" />
        <${TermsOfServiceEUPage} path="/terms-of-service-eu" />
        <${ModernSlaveryStatementPage} path="/modern-slavery-statement" />
        <${Landing} path="/landing" />
    </${Router}>
    <${Footer}/>
    </div>
</${StoreContext.Provider}>`;
    }

}

function Punchout({ id }: { id: string }) {


    const { state, dispatch } = ctx();
    const [error, setError] = useState('');

    api.punchoutLogin(id)
        .then(res => {
            dispatch({ type: 'logged', ...res });
            route('/');
        })
        .catch(err => {
            setError(err.message);
        });

    return html`${error}`;
}

export function OrdersOrQuotes(kind: 'orders' | 'quotes', draft = false) {

    const { state, dispatch } = ctx();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (kind == 'orders') {
            if (draft) {
                api.getDraftOrders()
                    .then(orders => {
                        dispatch({ type: 'SET_ORDERS', orders });
                        setLoading(false);
                    });
            } else {
                api.getOrders()
                    .then(orders => {
                        dispatch({ type: 'SET_ORDERS', orders });
                        setLoading(false);
                    });
            }
        } else {
            api.getQuotes()
                .then(quotes => {
                    dispatch({ type: 'SET_QUOTES', quotes });
                    setLoading(false);
                });
        }
        setLoading(true);
    }, []);

    if (loading) {
        return html`<div class="column main">
        <br/><br/>
        <${Loading}/>
        </div>`;
    }

    return html`<article class="column main">
        <section class="page-title-wrapper">
            <h1 class="page-title">
                <span class="base" data-ui-id="page-title-wrapper">My ${kind == 'orders' ? (draft ? 'Draft ' : '') + 'Orders' : 'Quotes'}</span>
            </h1>
        </section>
        <table class="striped">
        <thead>
            <tr>
                <th>#</th>
                <th style="width:200px">PO #</th>
                <th style="width:100px">Total</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            ${state[kind].items.map(order => 'gid' in order ?
        html`<tr>
                <td>${order.id}</td>
                <td>${order.po}</td>
                <td style="text-align:right;padding-right:10px">${currencySymbol(order.currency)}${(order.items.reduce((p, n) => p + n.price * n.quantity, 0) * 0.001).toFixed(2)}</td>
                <td>${order.created_at}</td>
                <td></td>
                <td></td>
                <td>Pending</td>
                <td class="a-right last"><a href="/account/${kind == 'orders' ? 'order' : 'quote'}/${order.gid}">View ${draft ? 'Draft ' : ''}${kind == 'orders' ? 'Order' : 'Estimate'}</a></td>
            </tr>`
        : html`
            <tr>
                <td>${order.tranid}</td>
                <td>${order.otherrefnum}</td>
                <td style="text-align:right;padding-right:10px">${currencySymbol(getCurrency(state))}${parseFloat(order.foreigntotal).toFixed(2)}</td>
                <td>${order.createddate}</td>
                <td>${getStatus(kind, order.status)}</td>
                <td class="a-right last"><a href="/account/${kind == 'orders' ? 'order' : 'quote'}/${order.id}">View ${kind == 'orders' ? 'Order' : 'Estimate'}</a></td>
            </tr>
            `)}
        </tbody>
    </table>
    </article>`;

}



const netsuiteStatusMap: { orders: { [key: string]: string }, quotes: { [key: string]: string } } = {
    orders: {
        A: 'Pending Approval',
        B: 'Pending Fulfillment',
        C: 'Cancelled',
        D: 'Partially Fulfilled',
        E: 'Pending Billing/Partially Fulfilled',
        F: 'Pending Billing',
        G: 'Billed',
        H: 'Closed'
    },
    quotes: {
        A: 'Open',
        B: 'Processed',
        C: 'Closed',
        V: 'Voided',
        X: 'Expired'
    }
}

function getStatus(kind: 'orders' | 'quotes', status: string) {
    return netsuiteStatusMap[kind][status] || status;
}

export function OrderOrQuoteDetails(kind: 'order' | 'quote', { id }: { id: string }) {

    const { dispatch } = ctx();


    const [order, setOrder] = useState(null as any as (netsuite.Order | netsuite.Estimate | commerce.Order));


    useEffect(() => {
        if (kind == 'order') {
            api.getOrder(id)
                .then(order => {
                    setOrder(order);
                });
        } else {
            api.getQuote(id)
                .then(quote => {
                    setOrder(quote);
                });
        }
    }, []);

    const currency = currencySymbol(order ? ('gid' in order ? order.currency : (order.type == 'estimate' ? order?.currencysymbol : order.currency.refName)) || 'USD' : '');

    if (order && 'gid' in order) {
        order.shipping_cost = order.shipping_cost || 0;
        order.subtotal = order.items.reduce((p, n) => p + n.quantity * n.price, 0) * 0.001;
        order.total = order.subtotal + order.shipping_cost + order.items.reduce((p, n) => p + (n.tax || 0), 0) * 0.001;
    }

    const onApproveClick = () => {
        if (order && 'gid' in order) {
            api.approveOrder(order.gid)
                .then(() => {
                    setOrder({ ...order, status: 'pending' });
                    alert('Order approved');
                });
        }
    };

    const onDraftEditClick = () => {
        if (!(order && 'gid' in order && order.status == 'draft')) {
            return alert('Can only be used with draft order');
        }
        if (confirm('Are you sure you want to edit this draft?')) {
            api.deleteDraft(order.gid)
                .then(() => {
                    dispatch({ type: 'draft.to.cart', value: order });
                    route('/cart');
                });
        }
    }

    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">${kind == 'order' ? (order?.status == 'draft' ? 'Draft Order' : 'Order') : 'Estimate'} Details</span>
            
        </h1>
    </div>
        ${order ? ('gid' in order ?
            html`<div class="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">
            <!--begin::Orders-->
            <div class="d-flex flex-column gap-7 gap-lg-10">
                <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                    <!--begin::Payment address-->
    <div class="card card-flush py-4 flex-row-fluid position-relative">
    <!--begin::Background-->
    <div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
        <i class="ki-solid ki-two-credit-cart" style="font-size: 14em">
        </i>
    </div>
    <!--end::Background-->
    
    <!--begin::Card header-->
    <div class="card-header">
        <div class="card-title">
            <h2>Billing Address</h2>
        </div>
    </div>
    <!--end::Card header-->
    
    <!--begin::Card body-->
    <div class="card-body pt-0">
        ${order.billing.deliver_to.join(', ')},<br/>
        ${order.billing.street.join(', ')},<br/>
        ${order.billing.city} ${order.billing.postal_code},<br/>
        ${order.billing.state},<br/>
        ${order.billing.country}.
    </div>
    <!--end::Card body-->
    </div>
    <!--end::Payment address-->
                    <!--begin::Shipping address-->
    <div class="card card-flush py-4 flex-row-fluid position-relative">
    <!--begin::Background-->
    <div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
        <i class="ki-solid ki-delivery" style="font-size: 13em">
        </i>
    </div>
    <!--end::Background-->
    
    <!--begin::Card header-->
    <div class="card-header">
        <div class="card-title">
            <h2>Shipping Address</h2>
        </div>
    </div>
    <!--end::Card header-->
    
    <!--begin::Card body-->
    <div class="card-body pt-0">
        ${order.shipping.deliver_to.join(', ')},<br/>
        ${order.shipping.street.join(', ')},<br/>
        ${order.shipping.city} ${order.shipping.postal_code},<br/>
        ${order.shipping.state},<br/>
        ${order.shipping.country}.
    </div>
    <!--end::Card body-->
    </div>
    <!--end::Shipping address-->                </div>
    
                <!--begin::Product List-->
    <div class="card card-flush py-4 flex-row-fluid overflow-hidden">
    <!--begin::Card header-->
    <div class="card-header">
        <div class="card-title">
            <h2>${kind == 'order' ? 'Order' : 'Estimate'} #${order.id} (PO: ${order.po})</h2>
        </div>
    </div>
    <!--end::Card header-->
    
    <!--begin::Card body-->
    <div class="card-body pt-0">
        <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                <thead>
                    <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        <th class="min-w-175px">Product</th>
                        <th class="min-w-100px text-end">SKU</th>
                        <th class="min-w-70px text-end">Qty</th>
                        <th class="min-w-100px text-end">Unit Price</th>
                        <th class="min-w-100px text-end">Total</th>
                    </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                    ${order.items.map(item => html`<tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <!--begin::Thumbnail-->
                                <a href="/p/${item.sku.split('-')[0]}" target="_blank" class="symbol symbol-50px">
                                    <span class="symbol-label" style="background-image:url(${api.getMoleculeSvg(item.sku.split('-')[0])});"></span>
                                </a>
                                <!--end::Thumbnail-->
    
                                <!--begin::Title-->
                                <div class="ms-5">
                                    <a href="/p/${item.sku.split('-')[0]}" target="_blank" class="fw-bold text-gray-600 text-hover-primary">${item.name}</a>
                                    <div class="fs-7 text-muted">Expected Ship Date: </div>
                                </div>
                                <!--end::Title-->
                            </div>
                        </td>
                        <td class="text-end">
                            ${item.sku}                        </td>
                        <td class="text-end">
                            ${item.quantity}
                        </td>
                        <td class="text-end">
                            ${currency}${(item.price * 0.001).toFixed(2)}
                        </td>
                        <td class="text-end">
                            ${currency}${(item.price * item.quantity * 0.001).toFixed(2)}
                        </td>
                    </tr>`)} 
                    <tr hidden>
                        <td colspan="4" class="text-end">
                            Subtotal
                        </td>
                        <td class="text-end">
                            ${currency}${order.subtotal?.toFixed(2)}
                        </td>
                    </tr>
                    <tr hidden>
                        <td colspan="4" class="text-end">
                            VAT (${(100 * ((order.total || 0) - (order.subtotal || 0) - (order.shipping_cost || 0)) / ((order.subtotal || 0) + (order.shipping_cost || 0))).toFixed(2)}%)
                        </td>
                        <td class="text-end">
                            ${currency}${((order.total || 0) - (order.subtotal || 0) - (order.shipping_cost || 0)).toFixed(2)}
                        </td>
                    </tr>
                    <tr hidden>
                        <td colspan="4" class="text-end">
                            Shipping Rate
                        </td>
                        <td class="text-end">
                            ${currency}${order.shipping_cost?.toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="fs-3 text-dark text-end">
                            Total
                        </td>
                        <td class="text-dark fs-3 fw-bolder text-end">
                            ${currency}${order.total?.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
            <!--end::Table-->
        </div>
    </div>
    <!--end::Card body-->
    </div>
    <!--end::Product List-->            </div>
    ${order.status == 'draft' ? html`<div class="draft-actions">
        <button onclick=${onDraftEditClick} >Edit</button><button onclick=${onApproveClick} >Approve</button>
        </div>` : html``}

            <!--end::Orders-->
        </div>`

            : (order.type !== 'estimate' ? html`<div class="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">
        <!--begin::Orders-->
        <div class="d-flex flex-column gap-7 gap-lg-10">
            <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                <!--begin::Payment address-->
<div class="card card-flush py-4 flex-row-fluid position-relative">
<!--begin::Background-->
<div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
    <i class="ki-solid ki-two-credit-cart" style="font-size: 14em">
    </i>
</div>
<!--end::Background-->

<!--begin::Card header-->
<div class="card-header">
    <div class="card-title">
        <h2>Billing Address</h2>
    </div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
    ${order.billingAddress?.addressee},<br/>
    ${order.billingAddress.addr1},<br/>
    ${order.billingAddress.city} ${order.billingAddress.zip},<br/>
    ${order.billingAddress.state},<br/>
    ${order.billingAddress.country}.
</div>
<!--end::Card body-->
</div>
<!--end::Payment address-->
                <!--begin::Shipping address-->
<div class="card card-flush py-4 flex-row-fluid position-relative">
<!--begin::Background-->
<div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
    <i class="ki-solid ki-delivery" style="font-size: 13em">
    </i>
</div>
<!--end::Background-->

<!--begin::Card header-->
<div class="card-header">
    <div class="card-title">
        <h2>Shipping Address</h2>
    </div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
    ${order.shippingAddress.addressee},<br/>
    ${order.shippingAddress.addr1},<br/>
    ${order.shippingAddress.city} ${order.shippingAddress.zip},<br/>
    ${order.shippingAddress.state},<br/>
    ${order.shippingAddress.country}.
</div>
<!--end::Card body-->
</div>
<!--end::Shipping address-->                </div>

            <!--begin::Product List-->
<div class="card card-flush py-4 flex-row-fluid overflow-hidden">
<!--begin::Card header-->
<div class="card-header">
    <div class="card-title">
        <h2>Order #${order.tranId} (PO: ${order.otherRefNum})</h2>
    </div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
    <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
            <thead>
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-175px">Product</th>
                    <th class="min-w-100px text-end">SKU</th>
                    <th class="min-w-70px text-end">Qty</th>
                    <th class="min-w-100px text-end">Unit Price</th>
                    <th class="min-w-100px text-end">Total</th>
                </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
                ${order.item.items.map(item => html`<tr>
                    <td>
                        <div class="d-flex align-items-center">
                            <!--begin::Thumbnail-->
                            <a href="/p/${item.item.refName.split('-')[0]}"  target="_blank" class="symbol symbol-50px">
                                <span class="symbol-label" style="background-image:url(${api.getMoleculeSvg(item.item.refName.split('-')[0])});"></span>
                            </a>
                            <!--end::Thumbnail-->

                            <!--begin::Title-->
                            <div class="ms-5">
                                <a href="/p/${item.item.refName.split('-')[0]}" target="_blank" class="fw-bold text-gray-600 text-hover-primary">${item.description}</a>
                                <div class="fs-7 text-muted">Expected Ship Date: ${item.expectedShipDate}</div>
                                <div class="fs-7 text-muted">Tracking Numbers: ${order.linkedTrackingNumbers}</div>
                            </div>
                            <!--end::Title-->
                        </div>
                    </td>
                    <td class="text-end">
                        ${item.item.refName}                        </td>
                    <td class="text-end">
                        ${item.quantity}
                    </td>
                    <td class="text-end">
                        ${currency}${item.amount}
                    </td>
                    <td class="text-end">
                        ${currency}${item.amount * item.quantity}
                    </td>
                </tr>`)} 
                <tr>
                    <td colspan="4" class="text-end">
                        Subtotal
                    </td>
                    <td class="text-end">
                        ${currency}${order.subtotal}
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="text-end">
                        VAT (${(100 * (order.total - order.subtotal - order.shippingCost) / (order.subtotal + order.shippingCost)).toFixed(2)}%)
                    </td>
                    <td class="text-end">
                        ${currency}${(order.total - order.subtotal - order.shippingCost).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="text-end">
                        Shipping Rate
                    </td>
                    <td class="text-end">
                        ${currency}${order.shippingCost.toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="fs-3 text-dark text-end">
                        Total
                    </td>
                    <td class="text-dark fs-3 fw-bolder text-end">
                        ${currency}${order.total.toFixed(2)}
                    </td>
                </tr>
            </tbody>
        </table>
        <!--end::Table-->
    </div>
</div>
<!--end::Card body-->
</div>
<!--end::Product List-->            </div>
        <!--end::Orders-->
    </div>` : html`<div class="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">
    <!--begin::Orders-->
    <div class="d-flex flex-column gap-7 gap-lg-10">
        <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
            <!--begin::Payment address-->
<div class="card card-flush py-4 flex-row-fluid position-relative">
<!--begin::Background-->
<div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
<i class="ki-solid ki-two-credit-cart" style="font-size: 14em">
</i>
</div>
<!--end::Background-->

<!--begin::Card header-->
<div class="card-header">
<div class="card-title">
    <h2>Billing Address</h2>
</div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
${order.billingaddress_text.split('\n').map(it => html`${it}<br/>`)}
</div>
<!--end::Card body-->
</div>
<!--end::Payment address-->
            <!--begin::Shipping address-->
<div class="card card-flush py-4 flex-row-fluid position-relative">
<!--begin::Background-->
<div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
<i class="ki-solid ki-delivery" style="font-size: 13em">
</i>
</div>
<!--end::Background-->

<!--begin::Card header-->
<div class="card-header">
<div class="card-title">
    <h2>Shipping Address</h2>
</div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
${order.billingaddress_text.split('\n').map(it => html`${it}<br/>`)}
</div>
<!--end::Card body-->
</div>
<!--end::Shipping address-->                </div>

        <!--begin::Product List-->
<div class="card card-flush py-4 flex-row-fluid overflow-hidden">
<!--begin::Card header-->
<div class="card-header">
<div class="card-title">
    <h2>Estimate #${order.tranid}</h2>
</div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
<div class="table-responsive">
    <!--begin::Table-->
    <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
        <thead>
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-175px">Product</th>
                <th class="min-w-100px text-end">SKU</th>
                <th class="min-w-70px text-end">Qty</th>
                <th class="min-w-100px text-end">Unit Price</th>
                <th class="min-w-100px text-end">Total</th>
            </tr>
        </thead>
        <tbody class="fw-semibold text-gray-600">
            ${order.item.items.map(item => html`<tr>
                <td>
                    <div class="d-flex align-items-center">
                        <!--begin::Thumbnail-->
                        <a href="/p/${item.item_display.split('-')[0]}" target="_blank" class="symbol symbol-50px">
                            <span class="symbol-label" style="background-image:url(${api.getMoleculeSvg(item.item_display.split('-')[0])});"></span>
                        </a>
                        <!--end::Thumbnail-->

                        <!--begin::Title-->
                        <div class="ms-5">
                            <a href="/p/${item.item_display.split('-')[0]}" target="_blank" class="fw-bold text-gray-600 text-hover-primary">${item.description}</a>
                        </div>
                        <!--end::Title-->
                    </div>
                </td>
                <td class="text-end">
                    ${item.item_display}                        </td>
                <td class="text-end">
                    ${item.quantity}
                </td>
                <td class="text-end">
                    ${currency}${item.amount}
                </td>
                <td class="text-end">
                    ${currency}${(parseFloat(item.amount) * parseFloat(item.quantity)).toFixed(2)}
                </td>
            </tr>`)} 
            <tr>
                <td colspan="4" class="text-end">
                    Subtotal
                </td>
                <td class="text-end">
                    ${currency}${parseFloat(order.subtotal).toFixed(2)}
                </td>
            </tr>
            <tr>
                <td colspan="4" class="text-end">
                    VAT (${(100 * (parseFloat(order.total) - parseFloat(order.subtotal) - parseFloat(order.custbody_cat_shipping_cost_total)) / (parseFloat(order.subtotal) + parseFloat(order.custbody_cat_shipping_cost_total))).toFixed(2)}%)
                </td>
                <td class="text-end">
                    ${currency}${(parseFloat(order.total) - parseFloat(order.subtotal) - parseFloat(order.custbody_cat_shipping_cost_total)).toFixed(2)}
                </td>
            </tr>
            <tr>
                <td colspan="4" class="text-end">
                    Shipping Rate
                </td>
                <td class="text-end">
                    ${currency}${parseFloat(order.custbody_cat_shipping_cost_total).toFixed(2)}
                </td>
            </tr>
            <tr>
                <td colspan="4" class="fs-3 text-dark text-end">
                    Total
                </td>
                <td class="text-dark fs-3 fw-bolder text-end">
                    ${currency}${parseFloat(order.total).toFixed(2)}
                </td>
            </tr>
        </tbody>
    </table>
    <!--end::Table-->
</div>
</div>
<!--end::Card body-->
</div>
<!--end::Product List-->            </div>
    <!--end::Orders-->
</div>`)) : 'Loading...'}

    </div>`;
}
export interface Address {
    id: number;
    attention: string;
    addressee: string;
    addr1: string;
    addr2: string;
    addrphone: string;
    addrtext: string;
    city: string;
    country: string;
    state: string;
    zip: string;
}

export default function Addresses() {

    const { state, dispatch } = ctx();

    const [selected, setSelected] = useState<Address | null>(null);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            return;
        }
        api.getAddresses()
            .then(addresses => {
                dispatch({ type: 'SET_ADDRESSES', addresses });
            });
    }, []);


    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">My Company Addresses 
            <!--<a href="/account/address-new" class="action primary">Add New Address</a>--></span>
        </h1>
        <div style="display:flex;gap: 10px; align-items:center">
            <input onInput=${(e: InputEvent) => setSearch((e.currentTarget as HTMLInputElement).value)} value=${search} type="text" placeholder="Search" />
            <button onclick=${() => selected ? dispatch({ type: 'user.billing', id: selected.id }) : null} style="width:240px" class="action primary ${selected && selected?.id == state.user?.default_billing ? 'disabled' : ''}">Set As Billing Default</button>
            <button onclick=${() => selected ? dispatch({ type: 'user.shipping', id: selected.id }) : null} style="width:240px" class="action primary ${selected && selected?.id == state.user?.default_shipping ? 'disabled' : ''}">Set As Shipping Default</button>
        </div>
    </div>
    <table class="striped">
    <thead>
    <tr style="font-weight:bold">
        <td></td>
        <td>Addressee</td>
        <td>Attention</td>
        <td>Street Address</td>
        <td>City</td>
        <td>Country</td>
        <td>State</td>
        <td>Zip/Postal Code</td>
        <td>Phone</td>
    </tr>
    </thead>
    <tbody>
        ${state.addresses
            .filter(it => Object.keys(it).filter(key => (it as any)[key]).find(key => (it as any)[key].toString().toLowerCase().includes(search.toLowerCase())))
            .sort((p, n) => p.addr1 > n.addr1 ? 1 : -1)
            .map(address => html`
        <tr style="border-top:lightgrey solid 2px;border-bottom:lightgrey solid 2px">
            <td><input onclick=${() => setSelected(address)} type="radio" name="selected" value=${address.id} /></td>
            <td>${address.attention}</td>
            <td>${address.addressee}</td>
            <td>${address.addr1}, ${address.addr2}</td>
            <td>${address.city}</td>
            <td>${address.country}</td>
            <td>${address.state}</td>
            <td>${address.zip}</td>
            <td>${address.addrphone}</td>
        </tr>
        `)}
    </tbody>
        
    </table>
    </div>`;
}

export function countItems(items: CartItem[]) {
    return items.reduce((p, n) => p + n.packs.reduce((p, n) => p + n.quantity, 0), 0);
}


export function LanguageSelector() {

    const { state, dispatch } = ctx();

    const selectLocation = (e: InputEvent) => {
        dispatch({ type: 'location.set', location: (e.target as HTMLSelectElement).value });
        location.reload();
    };

    return html`<select onchange=${selectLocation} value=${state.location} class="location-selector" style="background-color: #e0e1e2;color: #333;font-weight: bold;height: 36px;">
    <option value="">Select Location</option>
    <option value="GB">United Kingdom</option>
    <option value="EU">Europe</option>
    <option value="ROW">Rest Of the World</option>
</select>`;
}



export function useInput(init: any, callback?: any) {
    const [state, setState] = useState(init);
    return [state, (ev: any) => {
        setState(ev.target.value);
        if (callback) callback(ev.target.value);
    }];
}



export function isValidEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}



export function usePagination(total: number, initPage: number, initPageSize: number): [number, number, number[], (val: number) => void, (val: number) => void] {


    const calculatePages = (page: number, totalPages: number) => {
        const pages = [page - 2, page - 1, page, page + 1, page + 2, page + 3, page + 4].filter(p => p > 0 && p <= totalPages);
        if (pages.length > 5) pages.length = 5;
        return pages;
    };

    const [pageSize, setPageSize] = useState(initPageSize);
    const [page, setPage] = useState(initPage);

    const safeSetPage = (newPage: number) => {
        console.log('newPage', newPage);
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > Math.ceil((total) / pageSize)) {
            newPage = Math.ceil((total) / pageSize);
        }
        setPage(newPage);
        console.log('setpages', newPage, Math.ceil((total) / pageSize), 'PAGE', newPage)
        return [newPage, pageSize];
    }


    return [
        page,
        pageSize,
        calculatePages(page, Math.ceil(total / pageSize)),
        safeSetPage,
        (pageSize: number) => {
            setPageSize(pageSize);
            return [safeSetPage(1)[0], pageSize];
        },
    ]
}

render(html`<${App} />`, document.body)


export function AddNewAddress() {
    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">Add New Address</span>
        </h1>
    </div>
    <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
    <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;"></div>
    <form class="form-address-edit" 
        id="form-validate" enctype="multipart/form-data">
        <fieldset class="fieldset">
            <legend class="legend"><span>Contact Information</span></legend><br/>
            <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
            <input type="hidden" name="success_url" value=""/>
            <input type="hidden" name="error_url" value=""/>

            <div class="field field-name-firstname required">
                <label class="label" for="firstname"><span>First Name</span></label>
                <div class="control">
                    <input type="text" id="firstname" name="firstname" value="Punchout" title="First&#x20;Name"
                        class="input-text required-entry" data-validate="{required:true}"/>
                </div>
            </div>
            <div class="field field-name-lastname required">
                <label class="label" for="lastname"><span>Last Name</span></label>
                <div class="control">
                    <input type="text" id="lastname" name="lastname" value="Tester" title="Last&#x20;Name"
                        class="input-text required-entry" data-validate="{required:true}"/>
                </div>
            </div>


            <div class="field company ">
                <label for="company" class="label">
                    <span>
                        Organisation </span>
                </label>
                <div class="control">
                    <input type="text" name="company" id="company" value="" title="Organisation" class="input-text "/>
                </div>
            </div>


            <div class="field telephone required">
                <label for="telephone" class="label">
                    <span>
                        Phone Number </span>
                </label>
                <div class="control">
                    <input type="text" name="telephone" id="telephone" value="" title="Phone&#x20;Number"
                        class="input-text required-entry"/>
                </div>
            </div>


        </fieldset>
        <fieldset class="fieldset">
            <legend class="legend"><span>Address</span></legend><br/>
            <div class="field street required">
                <label for="street_1" class="label">
                    <span>Street Address</span>
                </label>
                <div class="control">
                    <input type="text" name="street[]" value="" title="Street Address" id="street_1"
                        class="input-text required-entry" />
                    <div class="nested">
                        <div class="field additional">
                            <label class="label" for="street_2">
                                <span>Street Address 2</span>
                            </label>
                            <div class="control">
                                <input type="text" name="street[]" value="" title="Street&#x20;Address&#x20;2"
                                    id="street_2" class="input-text "/>
                            </div>
                        </div>
                        <div class="field additional">
                            <label class="label" for="street_3">
                                <span>Street Address 3</span>
                            </label>
                            <div class="control">
                                <input type="text" name="street[]" value="" title="Street&#x20;Address&#x20;3"
                                    id="street_3" class="input-text "/>
                            </div>
                        </div>
                        <div class="field additional">
                            <label class="label" for="street_4">
                                <span>Street Address 4</span>
                            </label>
                            <div class="control">
                                <input type="text" name="street[]" value="" title="Street&#x20;Address&#x20;4"
                                    id="street_4" class="input-text "/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field city required">
                <label class="label" for="city"><span>City</span></label>
                <div class="control">
                    <input type="text" name="city" value="" title="City" class="input-text required-entry" id="city"/>
                </div>
            </div>
            <div class="field region required">
                <label class="label" for="region_id">
                    <span>State/Province</span>
                </label>
                <div class="control">
                    <select id="region_id" name="region_id" title="State/Province" class="validate-select region_id">
                        <option value="">Please select a region, state or province.</option>
                    </select>
                    <input type="text" id="region" name="region" value="" title="State/Province"
                        class="input-text validate-not-number-first " />
                </div>
            </div>
            <div class="field zip required">
                <label class="label" for="zip">
                    <span>Zip/Postal Code</span>
                </label>
                <div class="control">
                    <input type="text" name="postcode" value="" title="Zip/Postal Code" id="zip" class="input-text validate-zip-international "/>
                    <div role="alert" class="message warning" style="display:none">
                        <span></span>
                    </div>
                </div>
            </div>
            <div class="field country required">
                <label class="label" for="country"><span>Country</span></label>
                <div class="control">
                    <${CountrySelect} name="country"/>
                </div>
            </div>

            <input type="hidden" name="default_billing" value="1" />

            <input type="hidden" name="default_shipping" value="1"/>
        </fieldset>
        <div class="actions-toolbar">
            <div class="primary">
                <button type="submit" class="action save primary" data-action="save-address" title="Save&#x20;Address">
                    <span>Save Address</span>
                </button>
            </div>
            <div class="secondary">
                <a class="action back" href="/customer/account/">
                    <span>Go back</span>
                </a>
            </div>
        </div>
    </form>
</div>`;
}


export function Loading() {
    return html`<div class="loading gray">
        <div></div>
        <div></div>
        </div>`;
}